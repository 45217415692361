<script>
  export default {
    name: "PageLayout",

    props: {
      title: {
        type: String,
        default: ''
      }
    }
  }
</script>

<template>
  <div class="w-full h-full overflow-hidden overflow-y-auto p-4">
    <h3 v-if="title" class="text-xl font-medium leading-6 text-gray-900 mb-4">{{title}}</h3>
    <slot></slot>
  </div>
</template>
