import Http from "@/axios";

export const DeliveryCompanyRequest = {
    deliveryCompaniesTypes() {
        return new Promise((resolve, reject) => {
            Http.get(`/store/dc/types`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    deliveryCompanies(params) {
        return new Promise((resolve, reject) => {
            Http.get(`/store/dc`, {
                params: {...params}
            }).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    deliveryCompany(id) {
        return new Promise((resolve, reject) => {
            Http.get(`store/dc${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createDeliveryCompany(data) {
        return new Promise((resolve, reject) => {
            Http.post(`admin/store/dc`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createDeliveryCompanyType(data) {
        return new Promise((resolve, reject) => {
            Http.post(`admin/store/dc/types`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },


    updateDeliveryCompany(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`admin/dc/types/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },


    deleteDeliveryCompany(id) {
        return new Promise((resolve, reject) => {
            Http.delete(`admin/dc/types/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

}