import store from "@/store";
import helpers from "@/use/helpers";
import {DeliveryCompanyRequest} from "@/services/catalogue/deliveryCompany/requests";


export const DeliveryCompanyStore = {
    namespaced: true,
    state: {
        deliveryCompanies: [],
        deliveryCompaniesTypes: [],
        deliveryCompany: null,
        loading: false,
        loadingType: false,
        totalItem: 0
    },

    getters: {
        deliveryCompaniesTypes: (state) => {
            return state.deliveryCompaniesTypes?.map(type => ({value: type.id, label: type.name})) || []
        },
        deliveryCompanies: (state) => {
            return state.deliveryCompanies
        },

        deliveryCompany(state) {
            return state.deliveryCompany
        },

        loadingType(state) {
            return state.loadingType
        },

        loading(state) {
            return state.loading
        },

        totalItem(state) {
            return state.totalItem
        },

    },

    mutations: {
        SET_COMPANIES_TYPES: (state, value) => {
            state.deliveryCompaniesTypes = value
        },
        SET_COMPANIES: (state, value) => {
            state.deliveryCompanies = value
        },

        SET_COMPANY: (state, value) => {
            state.deliveryCompany = value
        },

        PUSH_COMPANY: (state, value) => {
            state.deliveryCompanies.unshift(value)
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        },

        SET_LOADING_TYPE: (state, value) => {
            state.loadingType = value
        },
    },

    actions: {
        getDeliveryCompaniesTypes({commit}) {
            commit('SET_LOADING_TYPE', true)
            DeliveryCompanyRequest.deliveryCompaniesTypes()
                .then((response) => {
                    commit('SET_COMPANIES_TYPES', response.data)
                })
                .catch(() => {
                    commit('SET_COMPANIES_TYPES', [])
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                commit('SET_LOADING_TYPE', false)
            })
        },

        getDeliveryCompanies({commit}, params) {
            commit('SET_LOADING', true)
            DeliveryCompanyRequest.deliveryCompanies(params)
                .then((response) => {
                    commit('SET_COMPANIES', response.data.data)
                })
                .catch(() => {
                    commit('SET_COMPANIES', [])
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                commit('SET_LOADING', false)
            })
        },

        getDeliveryCompany({commit}, id) {
            store.commit('SET_PAGE_LOADING', true)
            DeliveryCompanyRequest.deliveryCompany(id)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_COMPANY', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_COMPANY', null)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        // eslint-disable-next-line no-unused-vars
        createDeliveryCompanyType({commit, dispatch}, data) {
            commit('SET_LOADING_TYPE', true)
            DeliveryCompanyRequest.createDeliveryCompanyType(data)
                .then(() => {
                    dispatch('getDeliveryCompaniesTypes')
                    helpers.successAlertModal('Type added')
                })
                .catch(() => {
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                commit('SET_LOADING_TYPE', false)
            })
        },

        createDeliveryCompany({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            DeliveryCompanyRequest.createDeliveryCompany(data)
                .then(() => {
                    helpers.successAlertModal('Delivery company created')
                    dispatch('getDeliveryCompanies', data?.params)
                })
                .catch(() => {
                    commit('SET_COMPANY', {})
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => commit('SET_LOADING', false))
        },

        updateDeliveryCompany({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            DeliveryCompanyRequest.updateDeliveryCompany(data.form.id, data.form)
                .then((response) => {
                    if (response.success) {
                        commit('SET_LOADING', false)
                        dispatch('getDeliveryCompanies', data.params)
                        helpers.successAlertModal('Company updated')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        deleteDeliveryCompany({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            DeliveryCompanyRequest.deleteDeliveryCompany(data.id)
                .then((response) => {
                    if (response.success) {
                        commit('SET_LOADING', false)
                        helpers.successAlertModal('Company deleted')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                dispatch('getDeliveryCompanies', data.params)
            })
        }
    }
}