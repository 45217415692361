
export function validPassword(password) {
  let containsSpecialPattern = new RegExp(/[#?!@$%^&*-]/);
  let containsSpacePattern = new RegExp(/^\S*$/);
  return (
    containsSpecialPattern.test(password) && containsSpacePattern.test(password)
  );
}

export function unixTimestampToDate(timestamp, lang = navigator.language) {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp);
  const dLocal = date.toISOString();

  const timeMs = typeof dLocal === "number" ? dLocal : date.getTime();
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);
  const cutoffs = [
    60,
    3600,
    86400,
    86400 * 7,
    86400 * 30,
    86400 * 365,
    Infinity,
  ];
  const units = ["second", "minute", "hour", "day", "week", "month", "year"];
  const unitIndex = cutoffs.findIndex(
    (cutoff) => cutoff > Math.abs(deltaSeconds)
  );
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" });

  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}
