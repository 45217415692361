import DeliveryCompany from "@/services/catalogue/deliveryCompany/pages/DeliveryCompany.vue";

const CommunitiesPage = () => import(/* webpackChunkName: "CommunitiesPage" */ "@/services/catalogue/communities/pages/CommunitiesPage.vue")
const ToolsPage = () => import(/* webpackChunkName: "ToolsPage" */ "@/services/catalogue/tools/pages/ToolsPage.vue")
const SidebarComponent = () => import(/* webpackChunkName: "SidebarComponent" */ "@/components/common/SidebarComponent")

export const CommunitiesRoutes = [
  {
    path: "/communities",
    name: "CommunitiesPage",
    components: { default: CommunitiesPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Communities list",
    },
  },
  {
    path: "/tools",
    name: "ToolsPage",
    components: { default: ToolsPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Tools list",
    },
  },
  {
    path: "/delivery-company",
    name: "DeliveryCompany",
    components: { default: DeliveryCompany, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Delivery companies",
    },
  }
];