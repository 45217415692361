export const DeliveryColumns = [
    {
        title: 'Logo',
        dataIndex: 'logo',
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Countries',
        dataIndex: 'countries',
    },
    {
        title: 'Type',
        dataIndex: 'typeName',
    },
    {
        title: 'Link',
        dataIndex: 'link',
    },
    {
        title: 'Created',
        dataIndex: 'createdAt',
    },
    {
        title: 'Action',
        dataIndex: 'operation',
    },
];