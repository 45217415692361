<script>
import PageLayout from "@/layouts/PageLayout.vue";
import {mapActions, mapGetters} from "vuex";
import {unixTimestampToDate} from '@/use/utils'
import {LinkIcon, MagnifyingGlassIcon} from "@heroicons/vue/24/solid"
import {debounce} from "lodash";
import {DeliveryColumns} from "@/services/catalogue/deliveryCompany/config";
import {reactive, ref} from "vue";

export default {
  label: "DeliveryCompany",
  components: {
    LinkIcon,
    MagnifyingGlassIcon, PageLayout
  },

  setup() {
    const form = reactive({
      countries: [],
      name: null,
      link: null,
      typeId: null,
      description: null,
    });

    const typeForm = reactive({
      name: null,
      description: null,
    });


    const rules = {
      countries: [{required: true, message: 'Please select countries'}],
      name: [{required: true, message: 'Please enter company name'}],
      link: [{required: true, message: 'please enter link'}],
      typeId: [{required: true, message: 'Please choose the type'}],
      description: [{required: true, message: 'Please enter description'}],
    };

    const typeFormRules = {
      name: [{required: true, message: 'Please enter name'}],
      description: [{required: true, message: 'Please enter description'}],
    };

    const open = ref(false);
    const typeDrawer = ref(false);
    const showTypeDrawer = () => {
      typeDrawer.value = !typeDrawer.value;
    };
    const showDrawer = () => {
      open.value = true;
    };

    const onClose = () => {
      open.value = false;
    };

    const filterOption = (input, option) => {
      return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      form, open, rules, typeDrawer, typeForm,
      typeFormRules,
      showTypeDrawer, showDrawer, onClose, filterOption
    }
  },

  data() {
    return {
      DeliveryColumns,
      DeliveryCompanyType: "BUSINESS",
      query: '',
      statusOptions: [
        {
          value: 'Fulfillment',
          label: 'Fulfillment Services'
        },
        {
          value: "Domestic",
          label: 'Domestic Shipping',
        },
        {
          value: "International",
          label: 'International Shipping',
        },
        {
          value: "Freight",
          label: 'Freight Shipping',
        },
        {
          value: "Food",
          label: 'Food Delivery',
        },
        {
          value: "Grocery",
          label: 'Grocery Delivery',
        },
        {
          value: "Meal",
          label: 'Meal Kit Delivery',
        },
        {
          value: "Local",
          label: 'Local Moving Services',
        },
        {
          value: "Long-Distance",
          label: 'Long-Distance Moving Services',
        },
        {
          value: "Storage",
          label: 'Storage and Relocation Services',
        },
        {
          value: "Medical",
          label: 'Medical Supply Delivery',
        },
        {
          value: "Document",
          label: 'Document Courier Services',
        },
        {
          value: "Fragile",
          label: 'Fragile Item Delivery',
        },
        {
          value: "Eco-Friendly",
          label: 'Eco-Friendly Delivery',
        }
      ],
      current: 1,
      pageSize: 10,
      size: 10,
      openCreate: false,
      openUpdate: false,
    }
  },

  beforeMount() {
    this.$store.dispatch('CountriesStore/getCountries')
    this.$store.dispatch('DeliveryCompanyStore/getDeliveryCompaniesTypes')
    this.$store.dispatch('DeliveryCompanyStore/getDeliveryCompanies')
  },

  computed: {
    ...mapGetters({
      isLoading: 'DeliveryCompanyStore/loading',
      isLoadingType: 'DeliveryCompanyStore/loadingType',
      deliveryCompanies: 'DeliveryCompanyStore/deliveryCompanies',
      pageLoading: 'pageLoading',
      totalItem: 'DeliveryCompanyStore/totalItem',
      countries: "CountriesStore/countries",
      deliveryCompaniesTypes: 'DeliveryCompanyStore/deliveryCompaniesTypes',
    }),

    countriesList() {
      return this.countries?.map(country => ({
        value: country.countryId,
        label: country.countryName
      })) || []
    }
  },

  methods: {
    ...mapActions({
      createDeliveryCompanyAction: 'DeliveryCompanyStore/createDeliveryCompany',
      createDeliveryCompanyTypeAction: 'DeliveryCompanyStore/createDeliveryCompanyType',
      updateDeliveryCompanyAction: 'DeliveryCompanyStore/updateDeliveryCompany',
      deleteDeliveryCompanyAction: 'DeliveryCompanyStore/deleteDeliveryCompany'
    }),

    handlePagination(pagination) {
      const {current, pageSize} = pagination
      this.current = current || 1
      this.pageSize = pageSize || 20

      this.$store.dispatch('BrandsStore/getBrands', {
        page: this.current - 1,
        size: this.pageSize,
      })
    },

    search() {
      this.$store.dispatch('BrandsStore/getBrands', {
        q: this.query,
      })
    },

    debouncedSearch: debounce(function () {
      this.search();
    }, 500),

    deleteDeliveryCompany(id) {
      this.deleteDeliveryCompanyAction({
        value: id,
        params: {
          type: this.DeliveryCompanyType
        }
      })
    },

    createDeliveryCompanyType(form) {
      this.createDeliveryCompanyTypeAction(form).then(() => {
        this.typeDrawer = false
      })
    },


    createDeliveryCompany(form) {
      this.createDeliveryCompanyAction(form).then(() => {
        this.open = false
      })
    },

    getSwitchState(isPublished, DeliveryCompany) {

      this.updateDeliveryCompanyAction({
        form: {
          ...DeliveryCompany,
          isPublished: isPublished
        }, params: {
          type: this.DeliveryCompanyType
        }
      })
    },

    updateCategory() {

    },

    handleCommunitiesInfo(DeliveryCompany) {
      this.communitiesForm = {...DeliveryCompany}

      this.openUpdate = true
    },

    timestampToDate(d) {
      return unixTimestampToDate(d, 'en')
    },

  }
}
</script>

<template>
  <PageLayout title="Delivery Companies">
    <div class="flex justify-between items-center">
      <form class="flex w-full items-center space-x-3">
        <div class="relative w-full max-w-sm">
          <span class="absolute inset-y-2.5 left-3">
            <MagnifyingGlassIcon class="w-5 h-5 text-gray-600"/>
          </span>
          <a-input v-model:value="query" allow-clear @input="debouncedSearch" placeholder="Search..."/>
        </div>
      </form>
      <a-button type="primary" class="bg-blue-500 hover:bg-blue-700 text-white" @click="showDrawer">
        <template #icon>+</template>
        New Company
      </a-button>
    </div>

    <a-table
        class="mt-4" :columns="DeliveryColumns"
        :loading="pageLoading"
        :pagination="{
                 current: current,
                 pageSize: pageSize,
                 total: totalItem,
                 showTotal: (total) => `Total ${total} items`
               }"
        :data-source="deliveryCompanies" bordered
        @change="handlePagination">
      <template #bodyCell="{ column, text, record }">
        <template v-if="['updatedAt', 'createdAt'].includes(column.dataIndex)">
          <div>
            {{ timestampToDate(text) }}
          </div>
        </template>

        <template v-else-if="column.dataIndex === 'link'">
          <a-button type="link" :href="record.link" target="_blank">
            <LinkIcon class="h-5 w-5" aria-hidden="true"/>
          </a-button>
        </template>

        <template v-else-if="column.dataIndex === 'logo'">
          <a-image
              :width="40"
              src="https://aliyuncdn.antdv.com/logo.png"
              :preview="{
                src: 'https://aliyuncdn.antdv.com/logo.png',
              }"
          />
        </template>

        <template v-else-if="column.dataIndex === 'countries'">
            <span>
              <a-tag
                  v-for="country in record.countries"
                  :key="country"
                  :color=" country.length > 5 ? 'geekblue' : 'green'"
              >
                {{ country?.countryName?.toUpperCase() }}({{ country?.flag }})
              </a-tag>
            </span>
        </template>

        <template v-else-if="column.dataIndex === 'operation'">
          <div class="space-x-2">
            <button type="button" @click.prevent="handleCommunitiesInfo(record)"
                    class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs leading-4 text-gray-700 hover:bg-gray-50 focus:outline-none disabled:cursor-not-allowed disabled:opacity-30">
              Edit<span class="sr-only">, {{ record.title }}</span>
            </button>
            <button @click.prevent="deleteDeliveryCompany(record.id)"
                    class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs leading-4 text-gray-700 hover:bg-gray-50 focus:outline-none disabled:cursor-not-allowed disabled:opacity-30">
              Remove<span class="sr-only">, {{ record.id }}</span>
            </button>
          </div>
        </template>
      </template>
    </a-table>

    <a-drawer
        title="Create a new company"
        :width="520"
        :open="open"
        :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }"
        @close="onClose"
    >
      <a-drawer v-model:open="typeDrawer" title="New company type" width="320" :closable="false">
        <a-form
            @finish="createDeliveryCompanyType"
            :model="typeForm"
            :rules="typeFormRules" layout="vertical">
          <a-form-item label="Name" name="name">
            <a-input v-model:value="typeForm.name" placeholder="Please enter name"/>
          </a-form-item>
          <a-form-item

              label="Description" name="description">
            <a-textarea
                v-model:value="typeForm.description"
                :rows="4"
                placeholder="please enter description"
            />
          </a-form-item>
          <div class="space-x-4">
            <a-button @click="showTypeDrawer">Cancel</a-button>
            <a-button type="primary" html-type="submit" :disabled="isLoadingType" :loading="isLoadingType"
                      class="bg-blue-500 hover:bg-blue-700 text-white">Submit
            </a-button>
          </div>
        </a-form>
      </a-drawer>
      <a-form
          @finish="createDeliveryCompany"
          :model="form"
          :rules="rules" layout="vertical">
        <a-form-item label="Name of company" name="name">
          <a-input v-model:value="form.name" placeholder="Please enter company name"/>
        </a-form-item>
        <a-form-item label="Countries" name="countries">
          <a-select show-search
                    mode="multiple"
                    :filter-option="filterOption"
                    :options="countriesList"
                    v-model:value="form.countries"
                    allowClear placeholder="Search...">
            <template #option="{ value: val, label, icon }">
              <span role="img" :aria-label="val">{{ icon }}</span>
              &nbsp;&nbsp;{{ label }}
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="Type" name="typeId">
          <template #extra>
            <a-button @click="showTypeDrawer"
                      :disabled="isLoadingType" :loading="isLoadingType"
                      class="bg-blue-500 mt-2 hover:bg-blue-700 text-white" type="link">
              Add Type
            </a-button>
          </template>
          <a-select show-search
                    :filter-option="filterOption"
                    :options="deliveryCompaniesTypes"
                    v-model:value="form.typeId"
                    allowClear placeholder="Search...">
            <template #option="{ value: val, label, icon }">
              <span role="img" :aria-label="val">{{ icon }}</span>
              &nbsp;&nbsp;{{ label }}
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="Link" name="link">
          <a-input v-model:value="form.link" placeholder="Please enter link"/>
        </a-form-item>
        <a-form-item label="Description" name="description">
          <a-textarea
              v-model:value="form.description"
              :rows="4"
              placeholder="please enter  description"
          />
        </a-form-item>
        <div>
          <a-space>
            <a-button @click="onClose">Cancel</a-button>
            <a-button type="primary" html-type="submit" class="bg-blue-500 hover:bg-blue-700 text-white">Submit
            </a-button>
          </a-space>
        </div>
      </a-form>
    </a-drawer>
  </PageLayout>
</template>